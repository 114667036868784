import React from 'react'
import Slider from "react-slick";





class Testimonial extends React.Component {


  render() {

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };
  

    return (
      <div className='pt-100'>
      <section className="testimonial mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <div className="testi-left">
                <h4 className="heading-h2"><span className="heading-p bluecolor">Our Customers </span>  
                  Love what we do</h4>
                <p className="pharagraph" >Customer feedback and reviews are our major concerns! Here we have shared a few feedbacks of our reputed clients.
                </p>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 bluebg" >
              <div className="testi-right text-center">
                <Slider {...settings}>
                  <div className="testi-card">
                    <img className='lazyload' width="142px" height="142px" src="https://coinsclone.mo.cloudinary.net/images/whitelabel/p2p.png" alt="p2p trading Image1" />
                    <p className="pharagraph mb-0">Project Name
                    </p>
                    <p className="bluecolor"><b>P2P TRADING</b></p>
                    <p className="pharagraph">I have been working with Coinsclone for the past two years. I am really satisfied with their development and technical support as well. I hope to work with them for a long time.
                    </p>
                  </div>
                  <div className="testi-card">
                    <img className='lazyload' width="142px" height="142px" src="https://coinsclone.mo.cloudinary.net/images/whitelabel/lbx.png" alt="lbx" />
                    <p className="pharagraph mb-0">Project Name
                    </p>
                    <p className="bluecolor"><b>LBX</b></p>
                    <p className="pharagraph">Best thing about Coinsclone team is they understand your needs and make your requirements satisfied.
                    </p>
                  </div>
                  <div className="testi-card">
                    <img className='lazyload' width="142px" height="142px" src="https://coinsclone.mo.cloudinary.net/images/whitelabel/m7x.png" alt="m7f" />
                    <p className="pharagraph mb-0">Project Name
                    </p>
                    <p className="bluecolor"><b>M7F EXCHANGE</b></p>
                    <p className="pharagraph">Coinsclone has been a true partner helping us developing our platforms. They are always ready to face new challenges and have our full trust.
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default Testimonial