import React from 'react'

const Diverce = () => {


  return (
    <section className="choosedesire ychoose otc various mb-0 pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h2 className="heading-h2"><span className="heading-h3">Diverse <span className="bluecolor">White Label NFT </span>Marketplace </span>
            Solutions We Offer</h2>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/oPensea.png" alt="White label of OpenSea Image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                White label of OpenSea 
                </h3>
                <p className="pharagraph">
                <a href='https://www.coinsclone.com/opensea-clone-script/'>OpenSea white label software</a> is preferred by a lot of startups who look for customizable solutions to build their NFT Marketplace on the Ethereum blockchain. OpenSea has covered most of the top features and has a massive trading volume. Using a white label OpenSea solution, entrepreneurs can reach the market quickly.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/rarible.png" alt="White label of Rarible Image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                White label of Rarible
                </h3>
                <p className="pharagraph">
                <a href='https://www.coinsclone.com/rarible-clone-script/'>Rarible white label software</a> is helpful for entrepreneurs who are in search of a P2P NFT Marketplace exactly similar to Rarible. The platform has a tremendous reach and creating one like Rarible on the Ethereum blockchain is a great idea. Acquire white label solution of Rarible from Coinsclone to make millions in a short span of time.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/super-rare.png" alt="White label of SuperRare Image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                White label of SuperRare
                </h3>
                <p className="pharagraph">
                <a href='https://www.coinsclone.com/superrare-clone-script/'>White label SuperRare software</a> focuses on providing a ready-made NFT Marketplace solution based on Crypto art. SuperRare’s market cap has gone over $40 million and building an NFT Marketplace just like SuperRare can equally be a profitable business for crypto startups.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/blur.png" alt="White label of Blur Image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                White label of Blur
                </h3>
                <p className="pharagraph">
                <a href='https://www.coinsclone.com/blur-clone-script/'>White label Blur NFT Marketplace</a> is a ready-to-launch solution allowing entrepreneurs to create and deploy an NFT marketplace identical to Blur. Since Blur is at the top of the trending NFT Marketplaces, starting a business using white label solution can guarantee success on most occasions.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex lastblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/binance.png" alt="White label of Binance NFT Marketplace Image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                White label of Binance NFT Marketplace
                </h3>
                <p className="pharagraph">
                <a href='https://www.coinsclone.com/binance-nft-marketplace-clone-script/'>White label solution of Binance NFT Marketplace</a> offers a different kind of experience for users. Since Binance has a trusted community already, the white label of Binance NFT Marketplace has more benefits than most of the white label software.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/async.png" alt="White label of Async Image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                White label of Async
                </h3>
                <p className="pharagraph">
                As a ready-made solution, the white label of Async NFT Marketplace has everything that a startup aspires to. Async is a popular and growing platform with excellent potential. Get your NFT business underway with Async white label solution instantly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>  
  )
}

export default Diverce