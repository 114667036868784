import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'

class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Top Features</span>
               Of Our White Label NFT Marketplace</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Marketplace</h4>
                <p className="pharagraph">The NFT Marketplace we provide is an emerging platform that enables users to mint, bid, and buy the ownership rights to digital works of art via Non-Fungible Tokens (NFTs).
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1" >
              <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/marketplace.png"
                  alt="Marketplace"
                  width={478}
                />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1" > 
              <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/support-multichain.png"
                  alt="Support Multichain"
                  width={478}
                />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Support Multichain</h4>
                <p className="pharagraph">Our White Label platform supports all the top blockchains which allows your users to choose their preferred chain to mint, buy, and sell popular NFTs.</p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Crypto Wallets</h4>
                <p className="pharagraph">Choose and connect your preferred digital wallet for minting and buying NFTs. Our NFT Marketplace platform comes with a wide collection of crypto wallets like Metamask, Coinbase wallet, Trust wallet, etc.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1" >
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/crypto-wallets.png"
                  alt="Crypto Wallets"
                  width={478}
                />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1" >
              <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/decentralized-protocol.png"
                  alt="Decentralized protocol"
                  width={478}
                />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Decentralized protocol</h4>
                <p className="pharagraph">Users can track the transaction details of the listed NFTs in the marketplace through the respective network or chain in which our platform can be designed in a completely decentralized protocol.</p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Responsive Design</h4>
                <p className="pharagraph">Our software has an outstanding and user-friendly dashboard so that your users can get the best user experience on the platform and explore more.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1" >
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/responsive-design.png"
                  alt="Responsive Design"
                  width={478}
                />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures