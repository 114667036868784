import React from 'react'
import ButtonStartfree from '../ButtonComponent/ButtonStartfree'
import { StaticImage } from 'gatsby-plugin-image';


class BannerSection extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {  

    return (
      <section className="banner">
        <div className="container text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h1 className="main-heading">White Label NFT Marketplace</h1>
            <p className="sub-heading">Develop a coherent, bug-free White label NFT Marketplace that provides numerous benefits and allows you to earn outstanding profits with low investment.</p>
            <p className="sub-small">Out-and-out White Label NFT Marketplace solutions.</p>
          </div>
          <div className="quick text-center">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/whitelabel-nft-marketplace-development.png"
                  alt="Whitelabel NFT Marketplace Banner"
                  width={977}
                />
          </div>
          <p className="pharagraph">A productive and well-structured NFT Marketplace with the top features
          </p>
          <div className="text-center mt-4">
            <ButtonStartfree />
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection