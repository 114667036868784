import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image';

class WhyShould extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="whyshould pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Why Should </span> You Use 
              White Label NFT Marketplace?
              </h2>
              <p className="pharagraph">
              The NFT Marketplace development is a great business to start and has terrific potential to turn you into a millionaire. NFTs or non-fungible tokens are considered the future and many traders are already making hefty money through NFT trading. Several important celebrity names have also entered the NFT industry which means the scope for attracting a wide number of users is huge as well. A massive amount of revenue can be generated if you develop an NFT Marketplace. With our best-in-class white label NFT Marketplace, users can list, mint, and trade NFTs. Acquire a fantastic customized NFT Marketplace at a budget-friendly cost now. 
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/why-should-whitelabel-nft-marketplace.png"
                  alt="Whitelabel NFT Marketplace Banner"
                  width={515}
                />
              {/* <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/why-should-start-Whitelabel-nft-marketplace.svg" alt="Why Should Start White Label NFT Marketplace Image1" /> */}
            </div>
            <div className="col-lg-6 col-md-12">
              <p>Here are other reasons why you should use white-label NFT Marketplace.</p>
              <ul>
                <li className="pharagraph" >Unlike other complex NFT Marketplaces, white label services provide a simple interface design and features for users to explore.</li>
                <li className="pharagraph" >White label customized NFT Marketplaces offer packed security for their users by incorporating some of the best security features in the platform.
                </li>
                <li className="pharagraph" >The White label NFT Marketplace software can be deployed in a quick time compared to other methods like development from scratch. The time required to construct an NFT Marketplace from scratch is almost a year.</li>
                <li className="pharagraph" >Customized NFT Marketplaces are cost-friendly and affordable in different ranges. Hence, any startup can start an NFT Marketplace without worrying about the cost factor.</li>
                <li className="pharagraph" >Reaching the Crypto market in a short span is inevitable when it comes to readymade NFT Marketplaces. Businesses can largely profit on the back of this reason.</li>
              </ul>
              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould