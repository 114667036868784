import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react'


class CoreFeatures extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor"> Core Features</span> of our</span>
              White Label NFT Marketplace</h3>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Create Single and Multiple NFTs
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Various Digital Collectibles
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Support All File Formats
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Claim your Royalties
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Day/Night Mode
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Creator Ratings
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  High Security
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Fiat Currency Support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Activity Tracking
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Social Profiles & Chats
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Create Single and Multiple NFTs
                        </h4>
                        <p className="pharagraph">Our customized White label NFT Marketplace enables users to create single and multiple NFTs in a hassle-free manner.
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <StaticImage
                            src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/creator-nft's.png"
                            alt="Create Single and Multiple NFTs"
                            width={470}
                          />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Various Digital Collectibles  </h4>
                        <p className="pharagraph">Our platform supports various industries which include art, music, video, games, photography, sports, domains, etc…
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <StaticImage
                            src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/various-digital-collectibles.png"
                            alt="Various Digital Collectibles"
                            width={470}
                          />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4"> Support All File Formats</h4>
                        <p className="pharagraph">The tailor-made NFT Marketplace we provide supports uploading image, video, and audio domain names with different formats such as PNG, JPG, GIF, WEBP, and MP4 as well as 3D model file types.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <StaticImage
                            src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/support-all-file-formats.png"
                            alt="Support All File Formats"
                            width={470}
                          />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Claim your Royalties
                        </h4>
                        <p className="pharagraph">The NFTs minted can be claimed with royalties whenever sold to another user anywhere in the network and our smart contracts automatically credit the royalties to the creator automatically.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <StaticImage
                            src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/claim-your-royalties.png"
                            alt="Claim your Royalties"
                            width={470}
                          />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Day/Night Mode</h4>
                        <p className="pharagraph">Night mode reduces the luminance emitted by device screens and helps a pleasant viewing experience while users can also use the traditional light mode to get a professional look.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <StaticImage
                            src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/day-night-mode.png"
                            alt="Day/Night Mode"
                            width={470}
                          />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Creator Ratings</h4>
                        <p className="pharagraph">Users can rate the creators based on their performance and creativities which helps the buyers to choose the most popular ones.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <StaticImage
                            src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/creator-ratings.png"
                            alt="Creator Ratings"
                            width={470}
                          />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">High Security</h4>
                        <p className="pharagraph">We supply trackless multilayered security mechanisms that are the official mark of Blockchain technology. Multiple security protocols make it practically impossible to compromise privacy and financial transactions.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <StaticImage
                            src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/high-security.png"
                            alt="High Security"
                            width={470}
                          />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Fiat Currency Support</h4>
                        <p className="pharagraph">Users can, not only bid or buy NFTs using crypto payments, but also they can do that using fiat payments that can be done through credit/debit cards, payment gateways, etc.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <StaticImage
                            src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/fiat-currency-support.png"
                            alt="Fiat Currency Support"
                            width={470}
                          />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Activity Tracking</h4>
                        <p className="pharagraph">Users can track and view all the activities such as their listings, sales, bids, and transfers with a filter option based on the days.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <StaticImage
                            src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/activity-tracking.png"
                            alt="Activity Tracking"
                            width={470}
                          />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Social Profiles & Chats</h4>
                        <p className="pharagraph">Every member gets a profile, messaging system, activity wall, and much more. It serves as a place where they can keep up with all their discussions, reactions, applications, and points.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <StaticImage
                            src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/socialchat.png"
                            alt="Social Profiles & Chats"
                            width={350}
                          />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures